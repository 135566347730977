import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import classNames from "classnames";
import {
  Apps,
  Book,
  Chat,
  Dashboard,
  DirectionsCar,
  Email,
  FiberManualRecord,
  FileCopy,
  HomeWork,
  PinDrop,
  Settings,
  SwapHoriz,
} from "@material-ui/icons";

import LOGO from "../../assets/images/logo-white.png";
import { APICore, history } from "../../utils";
import { Button } from "@material-ui/core";
import { Bookmark } from "react-feather";

const SideBar = () => {
  const [showBookingsMenu, setShowBookingsMenu] = useState(false);
  const toggleBookingsMenu = () => {
    setShowBookingsMenu(!showBookingsMenu);
  };

  const api = new APICore();

  const adminData = api.getLoggedInData();

  useEffect(() => {
    setShowBookingsMenu(history?.location?.pathname.includes("/bookings"));
  }, [history?.location?.pathname]);

  return (
    <div className="sidebar">
      <div className="sidebar__content">
        <div className="sidebar__content-top">
          <a className="logo-link">
            <img src={LOGO} />
            {/* <h2>One Globe</h2> */}
          </a>
        </div>
        <div className="sidebar__content-divider" />
        <div className="sidebar__content-menu">
          <ul className="menu list-unstyled">
            <li
              className={classNames({
                active: history?.location?.pathname.includes("/dashboard"),
              })}
            >
              <Link to="/dashboard">
                <Dashboard /> Dashboard
              </Link>
            </li>
            {adminData?.role === "superadmin" && (
              <React.Fragment>
                <li
                  className={classNames({
                    active: history?.location?.pathname.includes("/products"),
                  })}
                >
                  <Link to="/products">
                    <DirectionsCar /> Products
                  </Link>
                </li>
                <li
                  className={classNames({
                    active: history?.location?.pathname.includes("/blogs"),
                  })}
                >
                  <Link to="/blogs">
                    <FileCopy /> Blogs
                  </Link>
                </li>
                <li
                  className={classNames({
                    active:
                      history?.location?.pathname.includes(
                        "/charging-stations"
                      ),
                  })}
                >
                  <Link to="/charging-stations">
                    <PinDrop /> Charging Stations
                  </Link>
                </li>
                <li
                  className={classNames({
                    active: history?.location?.pathname.includes("/dealers"),
                  })}
                >
                  <Link to="/dealers">
                    <HomeWork /> Dealers
                  </Link>
                </li>
                <li
                  className={classNames(
                    {
                      active: history?.location?.pathname.includes("/bookings"),
                    },
                    "sub-menu"
                  )}
                >
                  <Button
                    className="trigger"
                    disableRipple
                    onClick={toggleBookingsMenu}
                  >
                    <Book />
                    Bookings
                  </Button>

                  {showBookingsMenu && (
                    <div className="sub-menu-list">
                      <Link
                        to="/bookings/test-drive"
                        className={classNames({
                          active:
                            history?.location?.pathname.includes("/test-drive"),
                        })}
                      >
                        <FiberManualRecord /> Test Drive
                      </Link>
                      <Link
                        to="/bookings/vehicles"
                        className={classNames({
                          active:
                            history?.location?.pathname.includes("/vehicles"),
                        })}
                      >
                        <FiberManualRecord /> Vehicles
                      </Link>
                    </div>
                  )}
                </li>
                <li
                  className={classNames({
                    active: history?.location?.pathname.includes("/responses"),
                  })}
                >
                  <Link to="/responses">
                    <Chat /> Responses
                  </Link>
                </li>
                <li
                  className={classNames({
                    active: history?.location?.pathname.includes("/newsletter"),
                  })}
                >
                  <Link to="/newsletter">
                    <Email /> Newsletter
                  </Link>
                </li>

                <li
                  className={classNames({
                    active: history?.location?.pathname.includes("/exchange"),
                  })}
                >
                  <Link to="/exchange">
                    <SwapHoriz /> Vehicle Exchange
                  </Link>
                </li>

                <li
                  className={classNames({
                    active: history?.location?.pathname.includes("/activations"),
                  })}
                >
                  <Link to="/activations">
                    <Apps /> App Activations
                  </Link>
                </li>
                <li
                  className={classNames({
                    active: history?.location?.pathname.includes("/settings"),
                  })}
                >
                  <Link to="/settings">
                    <Settings /> Settings
                  </Link>
                </li>
              </React.Fragment>
            )}

            {adminData?.role === "admin" && (
              <React.Fragment>
                <li
                  className={classNames({
                    active: history?.location?.pathname.includes("/vehicles"),
                  })}
                >
                  <Link to="/bookings/vehicles">
                    <Bookmark /> Vehicle Bookings
                  </Link>
                </li>
                <li
                  className={classNames({
                    active: history?.location?.pathname.includes("/responses"),
                  })}
                >
                  <Link to="/responses">
                    <Chat /> Responses
                  </Link>
                </li>
              </React.Fragment>
            )}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default SideBar;
