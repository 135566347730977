import * as ACTIVATION_ACTION_TYPES from "./types";

export const activationFetchRequest = () => ({
  type: ACTIVATION_ACTION_TYPES.FETCH_ACTIVATION_LIST_REQUEST,
});

export const activationFetchRequestSuccess = (data) => ({
  type: ACTIVATION_ACTION_TYPES.FETCH_ACTIVATION_LIST_REQUEST_SUCCESS,
  data,
});

export const activationFetchRequestFailure = (error) => ({
  type: ACTIVATION_ACTION_TYPES.FETCH_ACTIVATION_LIST_REQUEST_FAILURE,
  error,
});

export const activationListCleanRequest = () => ({
  type: ACTIVATION_ACTION_TYPES.ACTIVATION_LIST_CLEAN_PROPS,
});

export const activationDetailFetchRequest = () => ({
  type: ACTIVATION_ACTION_TYPES.FETCH_ACTIVATION_DETAIL_REQUEST,
});

export const activationDetailFetchRequestSuccess = (data) => ({
  type: ACTIVATION_ACTION_TYPES.FETCH_ACTIVATION_DETAIL_REQUEST_SUCCESS,
  data,
});

export const activationDetailFetchRequestFailure = (error) => ({
  type: ACTIVATION_ACTION_TYPES.FETCH_ACTIVATION_DETAIL_REQUEST_FAILURE,
  error,
});

export const activationDetailCleanRequest = () => ({
  type: ACTIVATION_ACTION_TYPES.ACTIVATION_DETAIL_CLEAN_PROPS,
});

export const activationUpsertRequest = () => ({
  type: ACTIVATION_ACTION_TYPES.ACTIVATION_UPSERT_REQUEST,
});

export const activationUpsertRequestSuccess = (data) => ({
  type: ACTIVATION_ACTION_TYPES.ACTIVATION_UPSERT_REQUEST_SUCCESS,
  data,
});

export const activationUpsertRequestFailure = (error) => ({
  type: ACTIVATION_ACTION_TYPES.ACTIVATION_UPSERT_REQUEST_FAILURE,
  error,
});

export const activationUpsertCleanRequest = () => ({
  type: ACTIVATION_ACTION_TYPES.ACTIVATION_UPSERT_CLEAN_PROPS,
});
