export const FETCH_ACTIVATION_LIST_REQUEST = "FETCH_ACTIVATION_LIST_REQUEST";
export const FETCH_ACTIVATION_LIST_REQUEST_SUCCESS =
  "FETCH_ACTIVATION_LIST_REQUEST_SUCCESS";
export const FETCH_ACTIVATION_LIST_REQUEST_FAILURE =
  "FETCH_ACTIVATION_LIST_REQUEST_FAILURE";

export const ACTIVATION_LIST_CLEAN_PROPS = "ACTIVATION_LIST_CLEAN_PROPS";

export const FETCH_ACTIVATION_DETAIL_REQUEST = "FETCH_ACTIVATION_DETAIL_REQUEST";
export const FETCH_ACTIVATION_DETAIL_REQUEST_SUCCESS =
  "FETCH_ACTIVATION_DETAIL_REQUEST_SUCCESS";
export const FETCH_ACTIVATION_DETAIL_REQUEST_FAILURE =
  "FETCH_ACTIVATION_DETAIL_REQUEST_FAILURE";

export const ACTIVATION_DETAIL_CLEAN_PROPS = "ACTIVATION_DETAIL_CLEAN_PROPS";

export const ACTIVATION_UPSERT_REQUEST = "ACTIVATION_UPSERT_REQUEST";
export const ACTIVATION_UPSERT_REQUEST_SUCCESS =
  "ACTIVATION_UPSERT_REQUEST_SUCCESS";
export const ACTIVATION_UPSERT_REQUEST_FAILURE =
  "ACTIVATION_UPSERT_REQUEST_FAILURE";

export const ACTIVATION_UPSERT_CLEAN_PROPS = "ACTIVATION_UPSERT_CLEAN_PROPS";
